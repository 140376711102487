import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/PTZ/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SetPtzPerm';
import PTZTable01 from 'components/Web_User_Interface/1440p_Series/Features/PTZ/ptz01Table';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Pan, Tilt & Zoom",
  "path": "/Web_User_Interface/1440p_Series/Features/PTZ/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.",
  "image": "./WebUI_1440p_SearchThumb_Features_PTZ.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_PTZ.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Pan, Tilt & Zoom' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Deactivate your camera´s calibration run at start-up (will also deactivate the saved positions). Let your camera go to a saved position after a restart or limit the range of the pan & tilt motion for privacy concerns.' image='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/PTZ/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/PTZ/' crumbLabel="Pan, Tilt & Zoom" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Pan & Tilt Function`}</strong>{`
`}<em parentName="p">{`only in Pan, Tilt & Zoom Cameras`}</em></p>
    <h3 {...{
      "id": "ptz-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ptz-settings",
        "aria-label": "ptz settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ Settings`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0982732d4802f27f3c1e10177997a2a2/abe2a/1440p_Settings_Features_PTZ_Settings.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFUlEQVQoz22T3U4TURRG+xYmNaFR08bpHylRW9qZ6cxpkVJsmf+ZdsoAlQAhaIIUCF6pCXJnog/grXLP+y0zAy02cLFyzrlZ+fbeZ6fK5TLFYpFiqczL7DNUf4Lx5YbO6R9WJn9pn8yzMrmmf3GN8/WGhn+K0qhSLJVIp9NkMhlS+XyehEKB7IvniI2I0eQH/scrvA9XOEffH+AfXRKe/mItOKReW6b6ps5ieYlK5RWpQqHAlGwuR1OpE9g9HGMd11jH6nUw363OYXTbGH5Ef8OlqbRRlRa6toIi66QkSWKaMpvNouk6o82IYDBkOAxxXA/TsrHuSO62w3jvAMcM6YgOQtdRFUGjrt4K43QzoaYRBAGu6yaYpplgWVZCfLcti+2dHbYHPlHwlpZoosiCRqN5nzCW5nK5RBiGIwaDAZ7nzWTzp8lmNGa81Wc7VBCahqbqKLL6uHAqs217Lt2UOGEwHHF+sMVR2EVVFFZbGqqi3k85GcpdyWEY4vv+LNGMu7djWQzCkMtPJ/w83EGW67Q0/baH0wk/1sNYaBgGruvhWAa20cOyDDY8j65pcnE24ffZLuZaE1UVKLL2UKjr+qzkWOo4LoHv4Uf7eHtnjKJ9jk2TY9vF7fcQ1UVeV4pIUgFJyv/3sfP5pIe1Wo12u52IY4QQ6E0VYb5HjL/RM3Y5r9b4LOssV5Z4kn7KwkIm2ZJ4U/4ByN6W1pUzdCQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0982732d4802f27f3c1e10177997a2a2/e4706/1440p_Settings_Features_PTZ_Settings.avif 230w", "/en/static/0982732d4802f27f3c1e10177997a2a2/d1af7/1440p_Settings_Features_PTZ_Settings.avif 460w", "/en/static/0982732d4802f27f3c1e10177997a2a2/7f308/1440p_Settings_Features_PTZ_Settings.avif 920w", "/en/static/0982732d4802f27f3c1e10177997a2a2/85e95/1440p_Settings_Features_PTZ_Settings.avif 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0982732d4802f27f3c1e10177997a2a2/a0b58/1440p_Settings_Features_PTZ_Settings.webp 230w", "/en/static/0982732d4802f27f3c1e10177997a2a2/bc10c/1440p_Settings_Features_PTZ_Settings.webp 460w", "/en/static/0982732d4802f27f3c1e10177997a2a2/966d8/1440p_Settings_Features_PTZ_Settings.webp 920w", "/en/static/0982732d4802f27f3c1e10177997a2a2/d7218/1440p_Settings_Features_PTZ_Settings.webp 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0982732d4802f27f3c1e10177997a2a2/81c8e/1440p_Settings_Features_PTZ_Settings.png 230w", "/en/static/0982732d4802f27f3c1e10177997a2a2/08a84/1440p_Settings_Features_PTZ_Settings.png 460w", "/en/static/0982732d4802f27f3c1e10177997a2a2/c0255/1440p_Settings_Features_PTZ_Settings.png 920w", "/en/static/0982732d4802f27f3c1e10177997a2a2/abe2a/1440p_Settings_Features_PTZ_Settings.png 1067w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0982732d4802f27f3c1e10177997a2a2/c0255/1440p_Settings_Features_PTZ_Settings.png",
              "alt": "Web User Interface - 1440p Series - Features PTZ",
              "title": "Web User Interface - 1440p Series - Features PTZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable01 mdxType="PTZTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{` Here you can disable the cameras calibration run during start-up (please note that this also deactivates your saved positions!), send it to saved position 1 after a reboot and set the speed and number of auto-scans for the pan & tilt movement when you use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Live_Video/"
      }}>{`HSCAN or VSCAN`}</a>{`.`}</p>
    <p>{`The Pan & Tilt Limits will be displayed next to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Live_Video/"
      }}>{`PTZ Control Pad`}</a>{`. You can use them to limit the range of movement of your camera (see below).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      